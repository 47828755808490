import React from "react";

const Aanbod = (props) => {
    const regex = /(<([^>]+)>)/ig;
    const result = props.info ? props.info.replace(regex, '') : '';
    return (
        <div className="aanbod-item" onClick={props.onAanbodItemClick}>
            <h4 className="titel">{props.titel}</h4>
            <p className="info">{result ? result : ''}</p>
        </div>
    )
};

export default Aanbod;
