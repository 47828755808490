import React from "react";
const Modal = class extends React.Component {
  render() {
    return (
      <div className={"modal-overlay" + (this.props.open ? " open" : " closed")}>
        <div className="info-modal">
          <h4 className="titel">{this.props.title}</h4>
          <div className="info">
            {this.props.image ? <img className="info-image" src={this.props.image} alt="aanbod"/> : ''}
            <div dangerouslySetInnerHTML={{__html: (this.props.content.info || '')}} />
          </div>
          <div className="btn-container">
            <button className="btn-modal" onClick={this.props.onModalClick}>Sluiten</button>
          </div>
        </div>
      </div>
    );
  }
};
export default Modal;