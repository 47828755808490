import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {HTMLContent} from '../components/Content'
import backgroundImage from '../../static/img/background-2.jpg'
import PageHeader from '../components/PageHeader'
import Modal from '../components/Modal';
import Aanbod from '../components/Aanbod';
import {getUrlFromImage} from "../util/CommonUtil";

export class AanbodPageTemplate extends React.Component {
//     aanbodArray = [
//         {titel: '1. Manuele therapie', info: 'Dit is de info over dit aanbod item'},
//         {titel: '2. Sportkinesitherapie', info: 'Dit is de info over dit aanbod item'},
//         {titel: '3. Sportscreening', info: 'Dit is de info over dit aanbod item'},
//         {titel: '4. Zwangerschapsbegeleiding', info: `
//         <p>Zwanger zijn is een uniek moment in je leven.</p><p> Door deze zwangerschapsbegeleiding
// te volgen, zorgen we ervoor dat zowel jij als je partner in alle rust
// en vertrouwen kunnen bevallen!</p><br/>
// <p>Voor de bevalling </p><br/>
// <p>Deze begeleiding kan vroeg in de zwangerschap starten voor vragen en behandeling omtrent sport tijdens zwangerschap, ergonomie, fysieke ongemakken en bekkeninstabiliteit. Voor de perstechnieken en arbeidshoudingen raden we aan een afspraak te maken rond 34 weken samen met de partner.</p>
// <br /><p>Na de bevalling</p>
// <p>Zes weken na de bevalling kan je bij ons starten om terug fit te worden.
// De bekkenbodemregio, de buikspieren en de conditie worden grondig aangepakt.
// We bekijken ook samen met jou hoe je jouw baby kan verzorgen zonder de rug
// en het bekken te belasten. We gaan er samen voor zodat je optimaal en fit van
// je baby kan genieten.</p>
//         `},
//         {titel: '5. Rug-en nekschool', info: 'Dit is de info over dit aanbod item'},
//         {titel: '6. Pijneducatie', info: 'Dit is de info over dit aanbod item'},
//         {titel: '7. Loopanalyse', info: 'Dit is de info over dit aanbod item'},
//         {titel: '8. Revalidatie na operatie', info: 'Dit is de info over dit aanbod item'},
//         {titel: '9. Bike fitting', info: 'Dit is de info over dit aanbod item'},
//         {titel: '10. Persoonlijke coaching', info: 'Dit is de info over dit aanbod item'},
//     ]
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            modalTitel: '',
            modalContent: '',
            modalImage: ''
        };
    }
    closeModal = () => {
        this.setState({modalOpen: false, modalTitel: '', modalContent: '', modalImage: ''});
    }

    openModal = (item, titel, image) => {
        this.setState({modalOpen: true, modalTitel: titel, modalContent: item, modalImage: image});
    }
    render() {
        return (
            <div>
                <Modal open={this.state.modalOpen}
                       title={this.state.modalTitel}
                       content={this.state.modalContent}
                       image={this.state.modalImage}
                       onModalClick={this.closeModal}/>
                <PageHeader imageUrl={backgroundImage} titel={this.props.title}
                            subTitel={this.props.subtitle} motto={this.props.quote}/>
                <div className="container aanbod-container" style={{marginBottom: '50px'}}>
                    <section className="aanbod" style={{marginTop: '-30px', zIndex: 4, position: 'relative'}}>
                        {this.props.aanbodItems.map((aanbodItem, index) => (
                            <Aanbod key={index} titel={index+1 + '. ' + aanbodItem.naam}
                                    info={aanbodItem.info}
                                    onAanbodItemClick={() => this.openModal(aanbodItem, index + 1 + '. ' + aanbodItem.naam, aanbodItem && aanbodItem.image ? getUrlFromImage(aanbodItem.image) : '')}/>
                        ))}
                    </section>
                </div>
            </div>
        );
    }
}

AanbodPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const AanbodPage = ({data}) => {
    const {markdownRemark: post} = data;
    // console.log(post.frontmatter)
    // console.log(post.html)
    return (
        <Layout>
            <AanbodPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                subtitle={post.frontmatter.subtitle}
                backgroundImage={post.frontmatter.backgroundImage}
                aanbodItems={post.frontmatter.aanbodItems}
                quote={post.frontmatter.quote}
                content={post.html}
            />
        </Layout>
    )
}

AanbodPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default AanbodPage

export const aanbodPageQuery = graphql`
  query AanbodPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        quote
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aanbodItems {
            naam
            info
            image {
                      childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
            }
        }
      }
    }
  }
`
